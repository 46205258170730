<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar
        title="Notification"
        :showBack="true"
      ></topbar>
      <div class="content">
        <p class="font-12 sub-color font-b m-b-10">
          <span v-if="type == 1">SYSTEM MESSAGE</span>
          <span v-if="type == 2">OFFER NEWS</span>
          <span v-if="type == 3">ESTAMPS COLLECTED </span>
          <span v-if="type == 4">FRIENDS REFFERAL</span>
          <span v-if="type == 5">COLLECTOR CARD</span>
          <span v-if="type == 6">TRANSATION UPDATED</span>
        </p>
        <p class="font-18 main-color font-m m-b-10">
          Hello LKF Cool Japan! Come and enjoy your Novmeber offers
        </p>
        <p class="font-14 gery-color m-b-20">2019-04-10 01:32 pm</p>
        <p class="font-16 label-color m-b-30">
          Founded by Thomas Ritchie in 1870 Ritchies is one of Australia's
          oldest Supermarket & Liquor store chains. Below we share the amazing
          story of Thomas Ritchie and how he went from surviving a shipwreck to
          founding Australia's largest Independent Supermarket & Liquor company.
        </p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "notificationDetail",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/notification",
      });
    },
  },
};
</script>
<style scoped>
.content {
  padding-top: 0 !important;
}
</style>